<template>
    <div class="model">
        <!-- 右侧控件 -->
        <div class="right">
            <div class="div1 box around center hand" @click="next">
               <span class="right1"></span>
            </div>
            <div class="div2 box around center hand" @click="up">
                <span class="right2"></span>
            </div>
            <div class="div3 box center around">
                <span class="fs24 white">{{number}}</span>
                <span class="fs24 white">/</span>
                <span class="fs14 white">{{lenght}}</span>
            </div>
        </div>
        <!-- 下侧显示 -->
        <div class="bottom" :class="btShow==true? 'change1' : 'change' ">
            <div class="box justify center">
               <div class="white box around center" style="height:160px; width:40%">
                    <div>
                        <span class="fs40 mR20" style="color:#63BCF5">{{number}}</span>
                        <span class="fs30">{{ name }}/{{style | styleSelect1 }}风格</span>
                        <!-- <span>仿真场景仿真场景</span> -->
                    </div>
                </div>
                <!-- <div style="width:30%"></div> -->
                <div class="white box flexEnd center" style="height:160px;width:60%">
                    <div style="margin-right:92px">
                            <span class="fs26">空间：{{space | spaceSelect1 }}</span>
                    </div>
                    <div style="margin-right:92px">
                        <span class="fs26">环境：{{color | colorSelect1 }}</span>
                    </div>
                    <div class="box center">
                        <span class="fs26" style="margin-right:20px">质感：{{texture | textureSelect1 }}</span>
                         <span v-if="switchData == 1" class="hand" @click="change('add')">
                             <span class="switch1"></span>
                         </span>
                         <span v-if="switchData == 2" class="hand" @click="change('reduce')">
                             <span class="switch2"></span>
                         </span>
                    </div>
                </div>
            </div>
        </div>
         <!-- 触摸板 select -->
        <div class="select" v-on:mouseenter="enter()"  v-on:mouseleave ="out()"></div>

        <div class="box" style="height:100%">
            <img :src="src" alt="" class="img">
        </div>
    </div>
</template>


<script>

    var timer;

    import mapData from '../assets/js/mapData.js'

    export default {
    
        data() {
            return {
                flag: true,
                // src: require('../assets/img/01.jpg'),
                arr:[],
                number:'',  //编号
                name:'',   //三维场景  地图
                space:'',  //空间
                style:'',  //风格
                color:'',   //浅色  深色
                texture:'', //标准 半透明
                lenght:'',  //条数
                flag:0,
                switchData: 0,
                noSwitch: false,
                mapData: mapData,

                newList:[],
                btShow: true,

                // list:[
                //     {'src': require('../assets/img/01.jpg')},
                //     {'src': require('../assets/img/01.jpg')},
                //     {'src': require('../assets/img/01.jpg')},
                // ]
            }
        },
        created () {
            timer = setTimeout(() => {
                this.btShow = false;
            }, 3000);

            this.name = this.$route.query.name;
            
            //颜色深浅
            if( this.$route.query.color != undefined){
                this.color = this.$route.query.color;
                this.noSwitch = true;
            }

            if(this.color == 1){
                mapData.forEach((item =>{
                    if(item.color == 1){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            if(this.color == 2){
                mapData.forEach((item =>{
                    if(item.color == 2){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            //风格样式
            if( this.$route.query.style != undefined){
                this.style = this.$route.query.style;
                this.noSwitch = true;
            }

            if(this.style == 1){
                mapData.forEach((item =>{
                    if(item.style == 1){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            if(this.style == 2){
                mapData.forEach((item =>{
                    if(item.style == 2){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            //质感样式
            if( this.$route.query.texture != undefined){
                this.texture = this.$route.query.texture;
                this.noSwitch = true;
            }

            if(this.texture == 1){
                mapData.forEach((item =>{
                    if(item.texture == 1){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            if(this.texture == 2){
                mapData.forEach((item =>{
                    if(item.texture == 2){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }
            if(this.texture == 3){
                mapData.forEach((item =>{
                    if(item.texture == 3){
                        this.newList.push(item)
                    }
                }))
                this.mapData = this.newList;  
            }

            //传过id
            if( this.$route.query.id != undefined){
                this.showImage(this.$route.query.id);
                this.lenght = (this.mapData.length).toString().padStart(2,'0');
                return;
            }

            console.log('666', mapData)  
            this.number = (this.flag + 1).toString().padStart(2, '0');
            this.space = this.mapData[0].space;
            this.style = this.mapData[0].style;
            this.color = this.mapData[0].color;
            this.texture = this.mapData[0].texture;
            this.lenght = (this.mapData.length).toString().padStart(2,'0');
            this.src = this.mapData[0].src;

            if(this.noSwitch == true) {
                this.switchData = 0
            }else{
                this.switchData = this.mapData[0].switch
            }

             console.log(666666,this.noSwitch)
            
        },
        mounted () {

        },
        methods: {
            showImage(id){
                this.flag = id - 1;
                this.number = (this.flag+1).toString().padStart(2, '0');;
                this.style = this.mapData[this.flag].style;
                this.color = this.mapData[this.flag].color;
                this.space = this.mapData[this.flag].space;
                this.texture = this.mapData[this.flag].texture;
                this.src = this.mapData[this.flag].src;
                this.switchData = this.mapData[this.flag].switch;
                
            },

            //切换颜色
              //切换颜色模式
            change(val){
                if(val == 'add'){
                        this.next();
                }
                if(val == 'reduce'){
                    this.up();
                }
            },




            next() {
                clearTimeout(timer);    //关闭定时器 
                this.btShow = true;
                this.out();

                if(this.flag + 1 == this.lenght){
                    console.log('到头了')
                  return;
                }
                this.flag = this.flag + 1;
                this.number = (this.flag+1).toString().padStart(2, '0');;
                this.style = this.mapData[this.flag].style;
                this.color = this.mapData[this.flag].color;
                this.space = this.mapData[this.flag].space;
                this.texture = this.mapData[this.flag].texture;
                // this.switchData = this.mapData[this.flag].switch;
                this.src = this.mapData[this.flag].src

                 if(this.noSwitch == true){
                    this.switchData = 0
                }else{
                    this.switchData = this.mapData[this.flag].switch;
                }
               
            },
            up(){
                clearTimeout(timer);    //关闭定时器 
                this.btShow = true;
                this.out();

                 if(this.flag == 0){
                    console.log('到头了')
                  return;
                }
                this.flag = this.flag - 1;
                this.number = (this.flag+1).toString().padStart(2, '0');;
                this.style = this.mapData[this.flag].style;
                this.color = this.mapData[this.flag].color;
                this.space = this.mapData[this.flag].space;
                this.texture = this.mapData[this.flag].texture;
                // this.switchData = this.mapData[this.flag].switch;
                this.src = this.mapData[this.flag].src;

                 if(this.noSwitch == true){
                    this.switchData = 0
                }else{
                    this.switchData = this.mapData[this.flag].switch;
                }

            },

             //鼠标进入
            enter(){
                this.btShow = true
            },
            out(){
                timer = setTimeout(() => {
                    console.log(999)
                    this.btShow = false;
                }, 5000);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .model{
        width: 100%;
        height: 100%;
    }
    .img{
        width: 100%;
        height: 100%;
    }
    .right{
        position: absolute;
        right: 0;
        top: 40%;
        margin-right: 20px;
        // background-color: rgba(35,71,99, 1);
    }
    .div1{
        width: 58px;
        height: 74px;
        background-color: rgba(35,71,99, 0.6);
    }
    .right1{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconRight1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
    .right1:hover{
        background: url('../assets/images/iconRight2.png') no-repeat;
    }
     .div2{
        width: 58px;
        height: 74px;
        background-color: rgba(35,71,99, 0.6);
    }
    .right2{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconLeft1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
    .right2:hover{
        background: url('../assets/images/iconLeft2.png') no-repeat;
    }


    .div3{
        width: 58px;
        height: 38px;
        background-color: rgba(29,69,99,0.32);
    }

    .bottom{
        position: absolute;
        z-index: 2;
        bottom: 0;
        width: 100%;
        height: 160px;
        padding: 0 40px;
        background-color: rgba($color: #173145, $alpha: 0.57);
    }

     //触摸动画 
       // 进入   离开结束
    .v-enter,
    .v-leave-to{
        opacity: 0;
        transform: translateY(-160px);
    }

    // 入场的时间段   出场的时间段
    .v-enter-active,
    .v-leave-active{
        transition: all 0.8s ease;   //
    }

    .switch1{
        display: inline-block;
        width:58px;
        height:58px;
        background: url('../assets/images/btn.png') no-repeat;
        background-size:100% 100%;
    }
    .switch2{
        display: inline-block;
        width:58px;
        height:58px;
        background: url('../assets/images/btn.png') no-repeat;
        background-size:100% 100%;
    }

    .switch1:hover, .switch2:hover{
         background: url('../assets/images/btn1.png') no-repeat;
    }

     //触摸板
    .select{
        position: absolute;
        bottom: 0;
        height: 160px;
        width: 100%;
        z-index: 1;
        // border: 1px solid red;
    }



    .change{
        animation: myMou 0.5s;
        animation-fill-mode: forwards;
    }
    .change1{
        animation: myMou1 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou {
        0% {
            position: absolute;
            bottom: 0;
        }
        100% {
            position: absolute;
            bottom: -160px;
        }
    }
    @keyframes myMou1 {
        0% { 
            position: absolute;
            bottom: -160px;
        }
        100% {
             position: absolute;
            bottom: 0;
        }
    }

    //触摸板
    .select{
        position: absolute;
        bottom: 0;
        height: 160px;
        width: 100%;
        z-index: 1;
        // border: 1px solid red;
    }
   


</style>