// env 环境1浅色2神色       style风格1影像2科技3标准   texture质感1地理肌理2扁平3线条  space空间1 2D 2 2.5D

// 环境：1深色 2浅色 3绿色 4蓝色 5黄绿 6蓝橙 7棕色 8灰色 9单色 10黑色
// 风格：1 青绿山水 2中国风   3写实  4-low-poly   5科技  6扁平 7建筑绘画 8日式写意  9太空
// 空间：1 城市建筑 2 2.5D模型 3- 2D/3D混合 4- 3D模型  5 2D模型 6 地形图 7- 卫星图  8- 着色

const mapData=[
  {'number':'1', 'color': [1,3], 'style': [8], 'texture':'1','space': [5,8],'src': require('../img/map/1.jpg'),'id':'1', 'type':'2', 'switch':'1'},
  {'number':'2', 'color': [2,7,3], 'style': [8], 'texture':'1','space': [5,8],'src': require('../img/map/2.jpg'),'id':'2', 'type':'2', 'switch':'2'},
  {'number':'3', 'color': [1,3], 'style': [5], 'texture':'1','space': [5,7],'src': require('../img/map/3.jpg'),'id':'3', 'type':'2', 'switch':'0'},
  {'number':'4', 'color': [1], 'style': [7], 'texture':'1','space': [3],'src': require('../img/map/4.jpg'),'id':'4', 'type':'2', 'switch':'0'},
  {'number':'5', 'color': [2,1], 'style': [7], 'texture':'1','space': [3],'src': require('../img/map/5.jpg'),'id':'5', 'type':'2', 'switch':'0'},
  {'number':'6', 'color': [1,4,3], 'style': [2,5], 'texture':'1','space': [4,8],'src': require('../img/map/6.jpg'),'id':'6', 'type':'2', 'switch':'0'},
  {'number':'7', 'color': [8,4], 'style': [3,2], 'texture':'1','space': [4,8],'src': require('../img/map/7.jpg'),'id':'7', 'type':'2', 'switch':'0'},
  {'number':'8', 'color': [2,8], 'style': [9,5], 'texture':'1','space': [4,6],'src': require('../img/map/8.jpg'),'id':'8', 'type':'2','switch':'0'},
  {'number':'9', 'color': [1,3,4], 'style': [1,2], 'texture':'1','space': [4,8],'src': require('../img/map/9.jpg'),'id':'9', 'type':'2', 'switch':'0'},
  {'number':'10', 'color': [1], 'style': [5,6], 'texture':'2','space': [5],'src': require('../img/map/10.jpg'),'id':'10', 'type':'2','switch':'0'},
  {'number':'11', 'color': [8,2], 'style': [5], 'texture':'3','space': [2],'src': require('../img/map/11.jpg'),'id':'11', 'type':'2','switch':'0'},
  {'number':'12', 'color': [1], 'style': [5,6], 'texture':'3','space': [5],'src': require('../img/map/12.jpg'),'id':'12', 'type':'2','switch':'0'},
  {'number':'13', 'color': [1,7], 'style': [5,6], 'texture':'2','space': [5],'src': require('../img/map/13.jpg'),'id':'13', 'type':'2', 'switch':'0'},
  {'number':'14', 'color': [1,9], 'style': [5], 'texture':'1','space': [5,6],'src': require('../img/map/14.jpg'),'id':'14', 'type':'2', 'switch':'1'},
  {'number':'15', 'color':[1,9], 'style': [5,8], 'texture':'1','space':[5,6],'src': require('../img/map/15.jpg'),'id':'15', 'type':'2', 'switch':'2'},
  {'number':'16', 'color': [8,10], 'style': [5], 'texture':'2','space': [5],'src': require('../img/map/16.jpg'),'id':'16', 'type':'2', 'switch':'1'},
  {'number':'17', 'color': [4,10], 'style': [5], 'texture':'3','space': [5],'src': require('../img/map/17.jpg'),'id':'17', 'type':'2', 'switch':'2'},
  {'number':'18', 'color': [4,3], 'style': [5,3], 'texture':'1','space': [4,6],'src': require('../img/map/18.png'),'id':'18', 'type':'2', 'switch':'0'},
  {'number':'19', 'color': [10,3], 'style': [5], 'texture':'1','space': [5],'src': require('../img/map/19.png'),'id':'19', 'type':'2', 'switch':'0'},
  {'number':'20', 'color': [4,3], 'style': [5], 'texture':'1','space': [5],'src': require('../img/map/20.png'),'id':'20', 'type':'2', 'switch':'0'},
]
export default mapData;
